<template>
    <div class="com-article-component">
        <div class="com-title">
            <div>
                <h5>{{articleData.title}}</h5>
            </div>
            <!-- <div>
                <span>更多<img src="../assets/img/more.png" alt="" /></span>
            </div> -->
        </div>
        <div class="subject-year-wrap" v-if="$route.name == 'oa' || $route.name == 'preprint'">
            <div class="subject-wrap">
                学科：
                <el-select v-model="subject" placeholder="请选择" style="width: 210px;" @change="changeSubject">
                    <el-option v-for="item in subjectData" :key="item.name" :label="item.name" :value="item.id" >
                    </el-option>
                </el-select>
            </div>
            <div class="year-wrap" v-if="$route.name == 'oa'">
                年份：
                <el-select v-model="year" placeholder="请选择" @change="changeYear">
                    <el-option v-for="item in yearData" :key="item" :label="item" :value="item" >
                    </el-option>
                </el-select>
            </div>
        </div>
        <ul class="com-article">
            <li v-for="(item, index) in articleData.articleList" :key="index">
                <!-- appendixType appendix   --> 
                <h5 :class="[(item.appendixType == 'pdf' && item.appendix) ? 'cursor-h5' : '']" v-if="item.isInner==0" @click="goDetail(item)">
                    <!-- item.innerResourceId -->
                    <!-- 库外 -->
                    <!-- 没有附件不能点击 -->
                    <img v-if="$route.name=='oa'" src="../assets/img/lock.png" alt=""  style="width: 20px"/>
                    <img v-else-if="$route.name=='preprint'"  src="../assets/img/preprint.jpg" alt="" style="width: 20px; height: 15.4px;" />
                    <img v-else src="../assets/img/articleicon.png" alt="" />
                    <span>{{item.searchTitle ? item.searchTitle :item.title}}</span>
                </h5>
                <h5 v-else @click="goDetail(item)" class="cursor-h5">
                    <!-- item.innerResourceId -->
                    <!-- 库内 -->
                    <img v-if="$route.name=='oa'" src="../assets/img/lock.png" alt="" style="width: 20px"/>
                    <img v-else-if="$route.name=='preprint'"  src="../assets/img/preprint.jpg" alt="" style="width: 20px; height: 15.4px;" />
                    <img v-else src="../assets/img/articleicon.png" alt="" />
                    <span>{{item.searchTitle ? item.searchTitle : item.title}}</span>
                </h5>
                <div>
                    <p class="author" v-if="item.author">{{item.author}}</p>
                    <!-- 所属集刊 -->
                    <p class="name" v-if="item.source">{{item.source}}</p>
                    <p class="time" v-if="item.releaseDate">{{item.releaseDate}}</p>
                </div>
            </li>
        </ul>
        <div class="pagination-wrap">
            <el-pagination @current-change="handleCurrentChange" :current-page="currentPage" :page-size="14"
                layout="total, prev, pager, next, jumper" :total="articleData.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        articleData: {
            type: Object,
            default: {
                title: '',
                total: 0,
                articleList: [
                    {
                        title: '',
                        author: '',
                        book: '',
                        time: ''
                    }
                ]
            }
        },
        pageName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            currentPage: 1,
            subject: '',
            year: '全部',
            subjectData: [],
            yearData: []
        }
    },
    watch: {
        $route: 'init'
    },
    methods: {
        init() {
            if (this.$route.name == 'oa' || this.$route.name == 'preprint') {
                this.getSubjectData()
                this.getYear()
            }
        },
        changeSubject () {
            // this.subject
            this.currentPage = 1
            this.$emit('changeSubject', this.subject)
        },
        changeYear () {
            // this.year
            var x
            if (this.year == '全部') {
                x = ''
            } else {
                x = this.year.substring(0, 4)
            }
            this.currentPage = 1
            this.$emit('changeYear', x)
        },
        goDetail(item) {
            // console.log('item', item)
            // debugger
            if (this.$route.name == 'preprint') {
                this.$router.push({ path: '/pD/p', query: { id: item.id, pN: encodeURI(this.pageName.slice(0, 13)) } })
            } else if (this.$route.name == 'oa') {
                this.$router.push({ path: '/aD/a', query: { id: item.id, pN: encodeURI(this.pageName.slice(0, 13)) } })
            } else if (this.$route.name == 'expert' || this.$route.name == 'ins' || this.$route.name =='comPage') {
                if (item.isInner == 1) {
                    //库内
                    this.$router.push({ path: '/aD/a', query: { id: item.innerResourceId, pN: encodeURI(this.pageName.slice(0, 13)) } })
                } else if (item.isInner == 0) {
                    //库外   有pdf 打开
                    
                    // this.$router.push({ path: '/aD/a', query: { id: item.id, pN: encodeURI(this.pageName.slice(0, 13)) } })
                    if (this.$route.name == 'expert') {
                        this.$router.push({ path: '/eDA/e', query: { id: item.id, pN: encodeURI(this.pageName.slice(0, 13)) } })
                    } else if (this.$route.name == 'ins') {
                        this.$router.push({ path: '/iDA/i', query: { id: item.id, pN: encodeURI(this.pageName.slice(0, 13)) } })
                    }


                    // item.appendix
                    // item.appendixType


                    // appendix: "/admin/profile/upload/2020/12/23/af3f34bfcc91af2f9d4aead73a91f9e4.rar"
                    // appendixType: "pdf"
                } 
                
            }
            //  else {
            //     this.$router.push({ path: '/aD/a', query: { id: item.id, pN: encodeURI(this.pageName.slice(0, 13)) } })
            // }
        },
        handleCurrentChange(val) {
            this.$emit('pageChange', val)
        },
        getSubjectData() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/classify/xk'
            }).then(res => {
                if (res.data.code == 0) {
                    _this.subjectData = res.data.data
                    _this.subjectData.unshift({ name: '全部', id: '' })
                }
            })
        },
        getYear() {
            var nowDate = new Date()
            var nowYear = nowDate.getFullYear()
            var x = 1970
            this.yearData = []
            for (var i = 1970; i <= nowYear; i++) {
                this.yearData.unshift(i + '年')
            }
            this.yearData.unshift('全部')
        }
    },
    created() {
        this.init()
    }
}
</script>

<style lang="scss" scoped>
.com-article-component {
    position: relative;
    .subject-year-wrap {
        position: absolute;
        right: -7px;
        top: 10px;
        display: flex;
    }
    .com-title {
        border-bottom: none;
    }
    ul.com-article {
        margin-top: 7px; //17
        margin-bottom: 24px;
        display: flex;
        flex-wrap: wrap;
        background: url(../assets/img/border1.png);
        background-size: 1px 50%;
        background-repeat: repeat-y;
        background-position: 50% 0%;
        li {
            width: 530px;
            border-bottom: 1px solid #e6e6e6;
            h5 {
                
                margin-top: 20px;
                line-height: 20px;
                margin-bottom: 12px;
                display: flex;
                align-items: center;
                width: 530px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                img {
                    width: 16px;
                    height: 20px;
                    margin-right: 7px;
                }
                span {
                    width: 507px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    font-size: 18px;
                }
            }
            .cursor-h5 {
                cursor: pointer;
            }
            div {
                display: flex;
                margin-bottom: 19px;
                p {
                    color: #666666;
                    font-size: 16px;
                    line-height: 17px;
                    margin-right: 26px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                p.author {
                    width: 174px;
                }
                p.name {
                    width: 180px;
                }

                p.time {
                }
            }
        }
        li:nth-child(2n + 1) {
            margin-right: 34px;
        }
        li:nth-child(2n + 2) {
            margin-left: 33px;
            width: 536px;
            h5 {
                width: 536px;
            }
        }
        li:nth-last-child(2) {
            margin-bottom: 0;
        }
        li:last-child {
            margin-bottom: 0;
        }
    }
    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}
</style>