<template>
    <div class="com-book-component">
        <div class="com-title">
            <div>
                <h5>{{bookData.title}}</h5>
            </div>
            <!-- <div>
                <span>更多<img src="../assets/img/more.png" alt="" /></span>
            </div> -->
        </div>
        <div class="subject-year-wrap" v-if="$route.name == 'oa' || $route.name == 'preprint'">
            <div class="subject-wrap">
                学科：
                <el-select v-model="subject" placeholder="请选择" style="width: 210px;" @change="changeSubject">
                    <el-option v-for="item in subjectData" :key="item.name" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <div class="year-wrap" v-if="$route.name == 'oa'">
                年份：
                <el-select v-model="year" placeholder="请选择" @change="changeYear">
                    <el-option v-for="item in yearData" :key="item" :label="item" :value="item">
                    </el-option>
                </el-select>
            </div>
        </div>
        <ul class="com-book">
            <li v-for="(item, index) in bookData.bookList" :key="index" @click="goDetail(item)">
                <img v-if="item.titleImg" :src="item.titleImg" alt="" />
                <img v-else-if="item.img" :src="item.img" alt="">
                <img v-else alt="">
                <!-- <p v-if="item.isInner==0">
                    {{item.name}}
                </p> -->

                <p>{{item.title}}</p>

            </li>
            <!-- <li>
                <img src="../assets/img/book.png" alt="" />
                <p>比较政治学研究</p>
            </li>
            <li>
                <img src="../assets/img/book.png" alt="" />
                <p>比较政治学研究</p>
            </li>
            <li>
                <img src="../assets/img/book.png" alt="" />
                <p>比较政治学研究</p>
            </li>
            <li>
                <img src="../assets/img/book.png" alt="" />
                <p>比较政治学研究</p>
            </li>
            <li>
                <img src="../assets/img/book.png" alt="" />
                <p>比较政治学研究</p>
            </li> -->

        </ul>
        <div class="pagination-wrap">
            <el-pagination @current-change="handleCurrentChange" :page-size="10" :current-page="currentPage"
                layout="total, prev, pager, next, jumper" :total="bookData.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        bookData: {
            type: Object,
            default: {
                title: '',
                total: 0,
                bookList: [
                    {
                        title: '',
                        author: '',
                        book: '',
                        time: ''
                    }
                ]
            }
        },
        pageName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            currentPage: 1,
            subject: '',
            year: '全部',
            subjectData: [],
            yearData: []
        }
    },
    watch: {
        $route: 'init'
    },
    methods: {
        init() {
            if (this.$route.name == 'oa' || this.$route.name == 'preprint') {
                this.getSubjectData()

                this.getYear()
            }
        },
        goDetail(item) {
            // console.log('this.pageName', item)
            if (this.$route.name == 'oa') {
                //开放获取图书都是库内的
                if (item.resourceType == 'COLLECTED_PAPERS') {
                    this.$router.push({ path: '/c/c', query: { id: item.id, pN: encodeURI(this.pageName.slice(0, 13)) } })
                } else if (item.resourceType == 'WENJI' || item.resourceType == 'BOOK') {
                    this.$router.push({ path: '/collectionDetail/c', query: { id: item.id, pN: encodeURI(this.pageName) } })
                } else if (item.resourceType == 'ARTICLE') {
                    this.$router.push({ path: '/aD/a', query: { id: item.id, pN: encodeURI(this.pageName.slice(0, 13)) } })
                }
            } else {
                // 机构、学者、预印本   页面的书

                if (item.isInner == 1) {
                    //库内
                    if (item.innerResourceType == 'COLLECTED_PAPERS') {
                        this.$router.push({ path: '/c/c', query: { id: item.innerResourceId, pN: encodeURI(this.pageName.slice(0, 13)) } })
                    } else if (item.innerResourceType == 'WENJI' || item.innerResourceType == 'BOOK') {
                        this.$router.push({ path: '/collectionDetail/c', query: { id: item.innerResourceId, pN: encodeURI(this.pageName) } })
                    } else if (item.innerResourceType == 'ARTICLE') {
                        this.$router.push({ path: '/aD/a', query: { id: item.innerResourceId, pN: encodeURI(this.pageName.slice(0, 13)) } })
                    }
                } else {
                    //库外
                    if (this.$route.name == 'preprint') {
                        this.$router.push({ path: '/pDB/p', query: { id: item.id, pN: encodeURI(this.pageName.slice(0, 13)) } })
                    } else if (this.$route.name == 'expert' || this.$route.name == 'comPage') {
                        this.$router.push({ path: '/eDB/e', query: { id: item.id, pN: encodeURI(this.pageName.slice(0, 13)) } })
                    } else if (this.$route.name == 'ins') {
                        this.$router.push({ path: '/iDB/i', query: { id: item.id, pN: encodeURI(this.pageName.slice(0, 13)) } })
                    }
                }
            }
        },
        changeSubject() {
            // console.log(this.subject)
            this.currentPage = 1
            this.$emit('changeSubject', this.subject)
        },
        changeYear() {
            // console.log(this.year.slice(0, 4))
            var x
            if (this.year == '全部') {
                x = ''
            } else {
                x = this.year.substring(0, 4)
            }
            this.currentPage = 1
            this.$emit('changeYear', x)
        },
        handleCurrentChange(val) {
            this.$emit('pageChange', val)
        },
        getSubjectData() {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/classify/xk'
            }).then(res => {
                if (res.data.code == 0) {
                    _this.subjectData = res.data.data
                    _this.subjectData.unshift({ name: '全部', id: '' })
                }
            })
        },
        getYear() {
            var nowDate = new Date()
            var nowYear = nowDate.getFullYear()
            var x = 1970
            this.yearData = []
            for (var i = 1970; i <= nowYear; i++) {
                this.yearData.unshift(i + '年')
            }
            this.yearData.unshift('全部')
        }
    },
    created() {
        this.init()
        
    }
}
</script>

<style lang="scss" scoped>
.com-book-component {
    position: relative;
    .com-title {
        border-bottom: none;
    }
    .subject-year-wrap {
        position: absolute;
        right: -7px;
        top: 10px;
        display: flex;
    }
    ul.com-book {
        margin-top: 25px;
        display: flex;
        flex-wrap: wrap;
        li {
            margin-right: 25px;
            margin-bottom: 31px;
            img {
                width: 207px;
                height: 277px;
                margin-bottom: 16px;
                cursor: pointer;
                box-shadow: 0px 3px 9px 0px rgba(15, 11, 5, 0.29);
            }
            p {
                width: 207px;
                line-height: 16px;
                font-size: 16px;
                text-align: center;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
        li:nth-child(5n + 5) {
            margin-right: 0;
        }
    }
    .pagination-wrap {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}
</style>